<template>
  <div>
    <!-- 统计 -->
    <div class="card">
      <h4>
        基本信息
      </h4>
      <div class="count-list flex">
        <div class="count-item" @click="navigateTo('managerGoods')">
          <div>
            <i class="el-icon-s-goods"></i>
          </div>
          <div>
            <div class="counts">{{homeData.goodsNum ||0}}</div>
            <div>商品数量</div>
          </div>
        </div>
        <div class="count-item" @click="navigateTo('memberList')">
          <div>
            <i class="el-icon-s-custom"></i>
          </div>
          <div>
            <div class="counts">{{homeData.memberNum ||0}}</div>
            <div>会员数量</div>
          </div>

        </div>
        <div class="count-item" @click="navigateTo('orderList')">
          <div>
            <i class="el-icon-s-order"></i>
          </div>
          <div>
            <div class="counts">{{homeData.orderNum ||0}}</div>
            <div>订单数量</div>
          </div>

        </div>
        <div class="count-item" @click="navigateTo('shopList')">
          <div>
            <i class="el-icon-s-data"></i>
          </div>
          <div>
            <div class="counts">{{homeData.storeNum ||0}}</div>
            <div>店铺数量</div>
          </div>

        </div>
      </div>
    </div>

    <!-- 今日待办 -->
    <div class="card">
      <h4>今日待办</h4>
      <div class="todo-list flex">
        <div class="todo-item" @click="navigateTo('applyGoods')">
          <div class="counts">0</div>
          <div>待审核商品</div>
        </div>
        <div class="todo-item" @click="navigateTo('shopAuth')">
          <div class="counts">0</div>
          <div>待审核店铺</div>
        </div>
        <div class="todo-item" @click="navigateTo('orderComplaint')">
          <div class="counts">0</div>
          <div>待审核投诉</div>
        </div>
        <div class="todo-item" @click="navigateTo('afterSaleOrder')">
          <div class="counts">0</div>
          <div>待审核售后</div>
        </div>
        <div class="todo-item" >
          <div class="counts">0</div>
          <div>待审核分销提现</div>
        </div>
        <div class="todo-item" @click="navigateTo('accountStatementBill')">
          <div class="counts">0</div>
          <div>待审核分账</div>
        </div>
      </div>
    </div>

    <!-- 今日，流量概括 -->
    <div class="card flow">
      <div class="flow-list flex">
        <div class="flow-item ">
          <div class="flow-member">
            <div>当前在线人数</div>
            <span>
              {{homeData.currentNumberPeopleOnline || 0}}
            </span>
          </div>
          <div class="flow-wrapper">
            <h4>
              流量概括
            </h4>
            <div class="card flow-box flex">
              <div class="flow-box-item">
                <div>
                  今日访客数
                </div>
                <div class="counts">

                  {{homeData.todayUV || 0}}
                </div>
              </div>
              <div class="flow-box-item">
                <div>
                  昨日访客数
                </div>
                <div class="counts">
                  {{homeData.yesterdayUV || 0}}
                </div>
              </div>
            </div>

            <div class="flow-splice flex">
              <div class="flow-box-splice">
                <div>
                  前七日访客数
                </div>
                <div class="counts">
                  {{homeData.lastSevenUV || 0}}
                </div>
              </div>
              <div class="flow-box-splice">
                <div>
                  前三十日访客数
                </div>
                <div class="counts">
                  {{homeData.lastThirtyUV || 0}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="today-box">
          <h4> 今日概括</h4>
          <div class="today-list flex">
            <div class="today-item">
              <div>今日订单数</div>
              <span>{{homeData.todayOrderNum}}</span>
            </div>
            <div class="today-item">
              <div>今日交易额</div>
              <span>￥{{homeData.todayOrderPrice ? (homeData.todayOrderPrice  | unitPrice) : 0}}</span>
            </div>
            <div class="today-item">
              <div>今日新增店铺</div>
              <span>{{homeData.todayStoreNum || 0}}</span>
            </div>
            <div class="today-item">
              <div>今日新增会员数</div>
              <span>{{homeData.todayMemberNum || 0}}</span>
            </div>
            <div class="today-item">
              <div>今日上架商品数量</div>
              <span>{{homeData.todayGoodsNum || 0}}</span>
            </div>
            <div class="today-item">
              <div>今日新增评论</div>
              <span>{{homeData.todayMemberEvaluation || 0}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- chart -->
    <div class="card transform">
      <div>
        <h4>最近48小时在线人数（整点为准）</h4>
        <div id="historyMemberChart"></div>
      </div>
    </div>
    <!-- chart -->
    <div class="charts  flex">
      <div class="chart-item">
        <h4>流量走势</h4>
        <div id="pvChart"></div>
      </div>
      <div class="chart-item">
        <h4>交易趋势</h4>
        <div id="orderChart"></div>
      </div>
    </div>

    <!-- top10商品 -->
    <div class="card transform">
      <h4>热卖商品TOP10</h4>
      <Table stripe :columns="tophotGoodsColumns" :data="topHotGoodsData"></Table>
    </div>

    <!-- top10店铺 -->
    <div class="card transform">
      <h4>热卖店铺TOP10</h4>
      <Table stripe :columns="tophotShopsColumns" :data="topHotShopsData"></Table>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      tophotShopsColumns: [ // 表格表头
        {
          type: 'index',
          width: 100,
          title: '排名',
          align: 'center'
        },
        {
          title: '店铺名称',
          key: 'storeName'
        },

        {
          title: '价格',
          key: 'price',
          render: (h, params) => {
            return h(
              'div',
              this.$options.filters.unitPrice(params.row.price, '￥')
            )
          }
        },
        {
          title: '销量',
          key: 'num',
          width: 100,
          sortable: true
        }
      ],

      tophotGoodsColumns: [
        {
          type: 'index',
          width: 100,
          title: '排名',
          align: 'center'
        },
        {
          title: '商品名称',
          key: 'goodsName'
        },

        {
          title: '价格',
          key: 'price',
          render: (h, params) => {
            return h(
              'div',
              this.$options.filters.unitPrice(params.row.price, '￥')
            )
          }
        },
        {
          title: '销量',
          key: 'num',
          width: 100,
          sortable: true
        }
      ],
      topHotGoodsData: [], // 热卖商品集合
      topHotShopsData: [], // 热卖店铺集合
      awaitTodoData: '', // 今日待办集合
      homeData: '', // 首页数据
      pvChart: '', // 流量统计
      orderChart: '', // 订单统计
      historyMemberChart: '', // 最近会员流量统计
      params: { // 请求参数
        searchType: 'LAST_SEVEN'
      },
      // 订单传参
      orderParams: {
        searchType: 'LAST_SEVEN', // TODAY ,  YESTERDAY , LAST_SEVEN , LAST_THIRTY
        year: '',
        shopId: '',
        memberId: ''
      }
    }
  },
  methods: {},
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
@import "./home.scss";
</style>
